import { cn } from "@/lib/utils";
import { forwardRef, HTMLAttributes, ReactNode } from "react"

/**
 * Componente RemoteVideo que muestra un video mockeado con un contenedor para contenido adicional.
 * @param {ReactNode} children - Elementos React que se renderizarán dentro del contenedor de video.
 * @param {React.Ref<HTMLDivElement>} ref - Referencia al contenedor div del video.
 * @param {HTMLAttributes<HTMLDivElement>} props - Todas las propiedades que se pasarán al div contenedor.
 * @returns {JSX.Element} - El componente RemoteVideo renderizado.
 */
export const RemoteVideo = forwardRef<
	HTMLDivElement & { videoClassname?: string },
	HTMLAttributes<HTMLDivElement> & {
		children: ReactNode
		videoClassname?: string
	}
>(({ children, className, videoClassname, ...props }, ref) => {
	return (
		<div
			className={cn(
				'w-[384px] aspect-video bg-grey-200 relative rounded-xl',
				className,
			)}
			{...props}
		>
			<div
				ref={ref}
				className={cn(
					'absolute rounded-xl object-cover',
					videoClassname,
				)}
			>
			</div>
			{children}
		</div>
	)
})

RemoteVideo.displayName = "RemoteVideo";


