import { Icon, IconsNames } from '@/components/clientComponents/Icon';
import classNames from 'classnames';
import { ReactNode, forwardRef } from "react";
import {
  Portal as RadixPortal, 
  Arrow as RadixArrow, 
  Content as RadixContent, 
  Item as RadixItem, 
  Root as RadixRoot, 
  DropdownMenuProps,Trigger as RadixTrigger,
  DropdownMenuTriggerProps,
  DropdownMenuItemProps,
  DropdownMenuContentProps
} from '@radix-ui/react-dropdown-menu'

const sizes = {
  small: 'w-52',
  medium: 'w-72',
  large: 'w-96',
  full: 'w-full',
} as const

function Container({ children, width = 'full', className }: { children?: ReactNode, width: keyof typeof sizes, className?: string}) {

  return <div
    className={classNames(className, `relative rounded-lg transition-all ease-in flex items-center ${sizes[width]}`)}>
        {children}
  </div>
}

const Item = forwardRef<HTMLDivElement, DropdownMenuItemProps>( ( {children, className, ...props}, ref) => {
  return <RadixItem 
    ref={ref} 
    className={classNames(className,"group text-[13px] leading-none text-primary rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none hover:bg-gray-200")}
    {...props}
    >
      {children}
    </RadixItem>
  
}  )

Item.displayName = 'ItemMenu'

function Root({ children, ...props } : DropdownMenuProps){
    return <RadixRoot {...props}>
        {children}
    </RadixRoot>
}

interface ITriggger {
    name?: IconsNames, 
    disabled?: boolean, 
    width?: keyof typeof sizes
    'aria-label': string
}


const Trigger = forwardRef<HTMLButtonElement, DropdownMenuTriggerProps&ITriggger>((
    { 
        name = 'attachFile', 
        disabled = false, 
        width = 'full',
        children,
        "aria-label": ariaLabel,
        className,
        ...props
    },
        ref
    ) => {

      if (name) {
        return <RadixTrigger ref={ref} asChild>
            <button
              className={classNames(className, `rounded-lg ${sizes[width]} h-[35px] inline-flex items-center justify-center text-primary bg-white outline-none hover:bg-gray-200 hover:text-gray-600 focus:shadow-black focus:outline-none focus:border-none focus:shadow-none pr-1`)}
              aria-label={ariaLabel}
              disabled={disabled}
              aria-disabled={disabled}
              {...props}
            >
              <Icon size='size-5' name={name} aria-hidden />
            </button>
        </RadixTrigger>
    }

    return (
           <RadixTrigger ref={ref} {...props}>
            {children}
          </RadixTrigger>
          )
      
})

Trigger.displayName = 'iconTrigger'

const Content = forwardRef<HTMLDivElement,DropdownMenuContentProps>( 
  (
    {
      children, 
      side = 'top', 
      sideOffset = 5,
      className,
    }, 
    ref
    ) => {
  return  <RadixContent
              ref={ref}
              side={side}
              className={classNames(className, "min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[30]")}
              sideOffset={sideOffset}
            >
                {children}
            </RadixContent>
})

Content.displayName = 'Content'

export {
  RadixPortal as DropDownMenuPortal, 
  RadixArrow as DropDownMenuArrow,
  Content as DropDownMenuContent,
  Root as DropDownMenuRoot,
  Container as DropDownMenuContainer,
  Trigger as DropDownMenuTrigger,
  Item as DropDownMenuItem,
  DropdownMenuTriggerProps,
  DropdownMenuItemProps
}