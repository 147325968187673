import React, { forwardRef, ReactNode } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { cn } from '@/lib/utils'
import { ITabButton } from './ITabButton'
import { Loader } from '@/components/sharedComponents/Loader'
import { Text } from '@/components/sharedComponents/Text'

/** Content of button when is Loading, for internal use only */
function TabButtonContentOnLoading({ children }: { children: string }) {
	return (
		<div className="flex content-center justify-center items-center pb-1.5">
			<Text tag="span">{children}</Text>
			<Loader className="ml-1" size="size-4" />
		</div>
	)
}

function TabButtonContentOnDefault({
	children,
	selected,
	disabled,
}: {
	children: ReactNode
	selected: boolean | undefined
	disabled: boolean | undefined
}) {
	const styles = cn(
		'after:h-[2px]',
		'after:rounded',
		'relative',
		'top-[-0.15em]',
		"after:content-['']",
		'after:block',
		`after:bg-secondary`,
		'after:transition-all',
	)
	if (selected) {
		return (
			<div className={cn(styles, 'after:w-[40px]')}>
				<Text weight="font-semibold" tag="div">
					{children}
				</Text>
			</div>
		)
	}
	if (disabled) {
		return (
			<div className="pb-1.5">
				<Text tag="div">{children}</Text>
			</div>
		)
	}

	return (
		<div className={cn(styles, 'after:w-[0px] hover:after:w-[40px]')}>
			<Text tag="div">{children}</Text>
		</div>
	)
}

const TabButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ITabButton>(
	function TabButton(
		{
			disabled,
			loading,
			selected,
			children = 'Default',
			loadingText = 'Cargando...',
			color = 'secondary',
			size = 'text-s',
			className,
			action,
			...props
		},
		ref,
	) {
		const text = loading || disabled ? 'text-grey-3' : `text-${color}`

		const commonStyles = classNames(
			'px-4',
			'min-w-[48px]',
			'rounded-lg',
			'h-[48px]',
			'items-center',
			'justify-center',
			'flex',
			'focus-visible:outline focus-visible:outline-grey-1 focus-visible:outline-2 transition-all',
			`${size}`,
		)

		const buttonStyles = cn(commonStyles, 'bg-none', text, className)

		if (props.type === 'link') {
			return (
				<Link
					ref={ref as React.MutableRefObject<HTMLAnchorElement>}
					className={buttonStyles}
					aria-disabled={disabled || loading}
					onClick={action}
					{...props}
				>
					{loading ? (
						<TabButtonContentOnLoading>
							{loadingText}
						</TabButtonContentOnLoading>
					) : (
						<TabButtonContentOnDefault
							selected={selected}
							disabled={disabled}
						>
							{children}
						</TabButtonContentOnDefault>
					)}
				</Link>
			)
		}

		return (
			<button
				ref={ref as React.MutableRefObject<HTMLButtonElement>}
				className={buttonStyles}
				aria-disabled={disabled}
				disabled={disabled || loading}
				onClick={action}
				{...props}
			>
				{loading ? (
					<TabButtonContentOnLoading>
						{loadingText}
					</TabButtonContentOnLoading>
				) : (
					<TabButtonContentOnDefault
						disabled={disabled}
						selected={selected}
					>
						{children}
					</TabButtonContentOnDefault>
				)}
			</button>
		)
	},
)

export default TabButton
