import { Icon } from '../Icon'
import {
	Root,
	DialogTrigger,
	Portal,
	Overlay,
	Content,
	Title,
	Description,
	Close,
	DialogOverlayProps,
	DialogContentProps
} from '@radix-ui/react-dialog'
import { cn } from "@/lib/utils"

const OverlayTailwind = (
	props: DialogOverlayProps & React.RefAttributes<HTMLDivElement>,
) => {
	return (
		<Overlay
			className={cn(
				'bg-black bg-opacity-60 data-[state=open]:animate-overlayShow fixed inset-0',
				props?.className,
			)}
		/>
	)
}

const OcciContent = ({ className,closeAction, closeButton = true, ...props }: DialogContentProps & { closeButton?:boolean, closeAction? : () => void}) => {
	return (
		<Content
			className={cn(
				'data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
				className,
			)}
			{...props}
		>
			{props?.children}
			<Close onClick={closeAction} asChild>

					{closeButton && <Icon
					className={
						'rounded-full h-4 w-4 flex items-center justify-center absolute top-2 right-2'
					}
					name={'close'}
					size='size-4'
					color="text-grey-1"
				/>}
			</Close>
		</Content>
	)
}

export { Root, DialogTrigger, Portal, Title, Description, Close }

export { OverlayTailwind as Overlay, OcciContent as Content }
